import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};

	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: 48px 20%;
	}
	& .BHFooterMenus {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 20px 24px;
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: none;
			padding: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}
			& .MuiAccordionDetails-root {
				a {
					color: ${({ theme }) => theme.palette.footer.color};
				}
			}
		}
	}
	& .BHNewsletter {
		padding: 30px 25px 0;
		${({ theme }) => theme.breakpoints.up('xsm')} {
			padding: 30px 45px 0;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
		max-width: 255px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
